export default function StoryTextLeft({ titleText, cardText }) {
    // state

    // comportements

    // affichage (render)
    return (
        <section>
            <div className="storytelling-center-textcard">
                <span className="a-propos-sub-page-title-1">
                    { titleText }
                </span>
                <div className="storytelling-titled-text">
                    <p>
                        { cardText }
                    </p>
                </div>
            </div>
        </section>
    );
}
