import partenaire1 from "../../images/Partenaires/Partenaire_BaieMSM.png"
import partenaire2 from "../../images/Partenaires/PartenaireEurope.jpg"
import partenaire8 from "../../images/Partenaires/partenaireFondEurope.png"
import partenaire3 from "../../images/Partenaires/PartenaireBPI.png"
import partenaire4 from "../../images/Partenaires/PartenaireNormandie.jpg"
import partenaire5 from "../../images/Partenaires/PartenaireICI.png"
import partenaire6 from "../../images/Partenaires/PartenaireMSM.png"
import partenaire7 from "../../images/Partenaires/PartenaireVilledieu.png"
import "../../css/Footer/partenaires.css"
export default function Partenaires() {
    return (
            <div className="partenaire-section">
                <img className="partenaire1" alt="partenaire du site" src={partenaire1}/>
                <img className="partenaire2" alt="partenaire du site" src={partenaire2}/>
                <img className="partenaire8" alt="partenaire du site" src={partenaire8}/>
                <img className="partenaire3" alt="partenaire du site" src={partenaire3}/>
                <img className="partenaire4" alt="partenaire du site" src={partenaire4}/>
                <img className="partenaire5" alt="partenaire du site" src={partenaire5}/>
                <img className="partenaire6" alt="partenaire du site" src={partenaire6}/>
                <img className="partenaire7" alt="partenaire du site" src={partenaire7}/>
            </div>
    )
}