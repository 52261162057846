
//carte instagram simple html contenant des props. 
export default function InstagramCard(props) {
    return (
        <div className="instagram-card">
            <div className="shadow">
                <a className="anchor-instagram-card" href="https://www.instagram.com/fdepalmier/">
                <div className="instagram-card-img-container">
                    <img className="instagram-card-img" src={props.srcImg} alt="Logo" />
                  </div>
                </a>
            </div>
        </div>
    );
}
