import "../../../css/ServicesCSS/EvenementSection.css"

export default function EvenementSection1(props){

    return <section className='section-evenement1'>
        <div className='container-titre-evenmenement'><h2 className='titre-evenmenement'>{props.title1}</h2></div>
        <div className='contenu'>
            <img className="img-evenement" src={props.src1} alt="decoration"/>
            <p className='text-evenement'>{props.text1}</p>
        </div>
    </section>
}

