export default function StoryTextLeft({ cardText }) {
    // state

    // comportements

    // affichage (render)
    return (
        <section>
            <div className="storytelling-left-textcard">
                <div className="storytelling-titled-text">
                    <p>
                        { cardText }
                    </p>
                </div>
            </div>
        </section>
    );
}
