import NavBar from "./components/Navbar/NavBar";
import AnnuaireVegetalPage from "./pages/AnnuaireVegetalPage";
import AProposPage from "./pages/AProposPage";
import ContactPage from "./pages/ContactPage";
import AProposAnnickPage from "./pages/AProposAnnickPage";
import AProposForetPage from "./pages/AProposForetPage";
import AProposPalmeraiePage from "./pages/AProposPalmeraiePage";
import AProposVisionPage from "./pages/AProposVisionPage";
import MentionsLegalesPage from "./pages/MentionsLegalesPage";

import HomePage from "./pages/HomePage";
import { Route, Routes} from "react-router-dom";
import AbonnementPage from "./pages/AbonnementPage";
import EvenementPage from "./pages/EvenementPage";
import PretDePlantePage from "./pages/PretDePlantePage";
import ScrollToTop from "./lib/ScrollToTop";




function App() {
   
      
    return (
        <div className="display-site">
            <ScrollToTop />
            <NavBar/>
            
            <Routes>
       
        <Route exact path="/" element={<HomePage />} />
        <Route path="/annuaireVegetal" element={<AnnuaireVegetalPage />} />
        <Route path="/aPropos" element={<AProposPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/abonnement" element={<AbonnementPage />} />
        <Route path="/evenement" element={<EvenementPage />} />
        <Route path="/pretDePlante" element={<PretDePlantePage />} />
        <Route path="/aPropos/Annick" element={<AProposAnnickPage />} />
        <Route path="/aPropos/Foret" element={<AProposForetPage />} />
        <Route path="/aPropos/Palmeraie" element={<AProposPalmeraiePage />} />
        <Route path="/aPropos/Vision" element={<AProposVisionPage />} />
        <Route path="/MentionsLegalesPage" element={<MentionsLegalesPage />} />
      </Routes>

        </div>
    );
}

export default App;
