import React, { useState, useEffect } from "react";
import { getProduits } from "../../lib";
import croix from "../../images/croix.svg"

export default function ProductBox(props) {
  const [inputText, setInputText] = useState("");
  const [produit, setProduit] = useState([
    {
      "id_produit": 1,
      "name": "CITRONNIER 4 SAISONS",
      "price": "48.00",
      "category": "AUTRES"
    },
    {
      "id_produit": 2,
      "name": "KUMQUAT",
      "price": "48.00",
      "category": "AUTRES"
    },
    {
      "id_produit": 3,
      "name": "CITRON CAVIAR",
      "price": "48.00",
      "category": "AUTRES"
    },
    {
      "id_produit": 4,
      "name": "CHAMAEROPS PETIT ",
      "price": "16.00",
      "category": "PALMIERS"
    },
    {
      "id_produit": 5,
      "name": "CHAMAEROPS 6 ANS ",
      "price": "50.00 ",
      "category": "PALMIERS"
    },
    {
      "id_produit": 6,
      "name": "CACTUS TAILLE 1/2 oreilles mini ( au choix sheerri, Mojavensis, Alta)",
      "price": "18.00",
      "category": "AUTRES"
    },
    {
      "id_produit": 7,
      "name": "CACTUS TAILLE 2/3 oreilles mini ( au choix sheerri, Mojavensis, Alta)",
      "price": "25.00",
      "category": "AUTRES"
    },
    {
      "id_produit": 8,
      "name": "CACTUS TAILLE 3/4 oreilles mini ( au choix sheerri, Mojavensis, Alta)",
      "price": "30.00",
      "category": "AUTRES"
    },
    {
      "id_produit": 9,
      "name": "CORDYLLINE AUSTRALIS (tronc 25 cm)",
      "price": "45.00",
      "category": "AUTRES"
    },
    {
      "id_produit": 10,
      "name": "YUCCA FILAMENTOSA  (classique ou 2 couleurs)",
      "price": "35.00",
      "category": "AUTRES"
    },
    {
      "id_produit": 11,
      "name": "Yucca Glauca",
      "price": "40.00",
      "category": "AUTRES"
    },
    {
      "id_produit": 12,
      "name": "AGAVE TAILLE 1 ( Au choix Americana, Montana, Ovatifolia)",
      "price": "15.00",
      "category": "AGAVE"
    },
    {
      "id_produit": 13,
      "name": "AGAVE TAILLE 2 ( Au choix Americana, Montana, Ovatifolia)",
      "price": "25.00",
      "category": "AGAVE"
    },
    {
      "id_produit": 14,
      "name": "AGAVE TAILLE 3 ( Au choix Americana, Montana, Ovatifolia)",
      "price": "35.00",
      "category": "AGAVE"
    },
    {
      "id_produit": 15,
      "name": "AGAVE TAILLE 4 ( Au choix Americana, Montana, Ovatifolia)",
      "price": "45.00",
      "category": "AGAVE"
    },
    {
      "id_produit": 16,
      "name": "AGAVE TAILLE 5 ( Au choix Americana, Montana, Ovatifolia)",
      "price": "60.00",
      "category": "AGAVE"
    },
    {
      "id_produit": 17,
      "name": "BANANIER au chix SP Helen, Bajou, Red Tigger)",
      "price": "25.00",
      "category": "AUTRES"
    },
    {
      "id_produit": 18,
      "name": "BOUQUET PETIT",
      "price": "15.00",
      "category": "BOUQUETS"
    },
    {
      "id_produit": 19,
      "name": "BOUQUET MOYEN",
      "price": "25.00",
      "category": "BOUQUETS"
    },
    {
      "id_produit": 20,
      "name": "BOUQUET GRAND",
      "price": "35.00",
      "category": "BOUQUETS"
    },
    {
      "id_produit": 21,
      "name": "BOUQUET SUR MESURE",
      "price": "0.00",
      "category": "BOUQUETS"
    },
    {
      "id_produit": 22,
      "name": "Trachycarpus Fortunei petit (pot)",
      "price": "20.00",
      "category": "PALMIERS"
    },
    {
      "id_produit": 23,
      "name": "Trachycarpus Fortunei tronc 20/30 cm en motte",
      "price": "55.00",
      "category": "PALMIERS"
    },
    {
      "id_produit": 24,
      "name": "Trachycarpus Fortunei tronc 30/40 cm",
      "price": "66.00",
      "category": "PALMIERS"
    },
    {
      "id_produit": 25,
      "name": "Trachycarpus Fortunei tronc 40/50 cm",
      "price": "88.00",
      "category": "PALMIERS"
    },
    {
      "id_produit": 26,
      "name": "Trachycarpus Fortunei tronc 50/60 cm",
      "price": "121.00",
      "category": "PALMIERS"
    },
    {
      "id_produit": 27,
      "name": "Trachycarpus Fortunei tronc 60/70 cm",
      "price": "143.00",
      "category": "PALMIERS"
    },
    {
      "id_produit": 28,
      "name": "Trachycarpus Fortunei tronc 70/80 cm",
      "price": "165.00",
      "category": "PALMIERS"
    },
    {
      "id_produit": 29,
      "name": "Trachycarpus Fortunei tronc 80/90 cm",
      "price": "176.00",
      "category": "PALMIERS"
    },
    {
      "id_produit": 30,
      "name": "Trachycarpus Fortunei tronc 90/100 cm",
      "price": "209.00",
      "category": "PALMIERS"
    },
    {
      "id_produit": 31,
      "name": "Trachycarpus Fortunei tronc 100/110 cm",
      "price": "242.00",
      "category": "PALMIERS"
    },
    {
      "id_produit": 32,
      "name": "Trachycarpus Fortunei tronc 110/120 cm",
      "price": "264.00",
      "category": "PALMIERS"
    },
    {
      "id_produit": 33,
      "name": "Trachycarpus Fortunei tronc 120/130 cm",
      "price": "330.00",
      "category": "PALMIERS"
    },
    {
      "id_produit": 34,
      "name": "Trachycarpus Fortunei tronc 130/140 cm",
      "price": "385.00",
      "category": "PALMIERS"
    },
    {
      "id_produit": 35,
      "name": "Trachycarpus Fortunei tronc 140/150 cm",
      "price": "451.00",
      "category": "PALMIERS"
    },
    {
      "id_produit": 36,
      "name": "Trachycarpus Fortunei tronc 150/160 cm",
      "price": "495.00",
      "category": "PALMIERS"
    },
    {
      "id_produit": 37,
      "name": "Trachycarpus Fortunei tronc 160/170 cm",
      "price": "528.00",
      "category": "PALMIERS"
    },
    {
      "id_produit": 38,
      "name": "Trachycarpus Fortunei tronc 170/180 cm",
      "price": "561.00",
      "category": "PALMIERS"
    },
    {
      "id_produit": 39,
      "name": "Trachycarpus Fortunei tronc 180/190 cm",
      "price": "583.00",
      "category": "PALMIERS"
    },
    {
      "id_produit": 40,
      "name": "Trachycarpus Fortunei tronc 190/200 cm",
      "price": "605.00",
      "category": "PALMIERS"
    },
    {
      "id_produit": 41,
      "name": "Trachycarpus Fortunei tronc 200/210 cm",
      "price": "627.00",
      "category": "PALMIERS"
    },
    {
      "id_produit": 42,
      "name": "Trachycarpus Fortunei tronc 210/220 cm",
      "price": "660.00",
      "category": "PALMIERS"
    },
    {
      "id_produit": 43,
      "name": "Trachycarpus Fortunei tronc 220/230 cm",
      "price": "715.00",
      "category": "PALMIERS"
    },
    {
      "id_produit": 44,
      "name": "CYCAS",
      "price": "190.00",
      "category": "AUTRES"
    },
    {
      "id_produit": 45,
      "name": "OLIVIER",
      "price": "30.00",
      "category": "AUTRES"
    },
    {
      "id_produit": 46,
      "name": "FURCREA",
      "price": "50.00",
      "category": "AUTRES"
    },
    {
      "id_produit": 47,
      "name": "AGAPANTHE ( GROS POT)",
      "price": "26.00",
      "category": "AUTRES"
    },
    {
      "id_produit": 48,
      "name": "CACHE POT TERRACOTA DIAM 12 CM",
      "price": "10.00",
      "category": "POTS"
    },
    {
      "id_produit": 49,
      "name": "CACHE POT TERRACOTA DIAM 17 CM",
      "price": "15.00",
      "category": "POTS"
    },
    {
      "id_produit": 50,
      "name": "CACHE POT ZINC  DIAM 27 CM",
      "price": "15.00",
      "category": "POTS"
    },
    {
      "id_produit": 51,
      "name": "CACHE POT ZINC  DIAM 20 CM",
      "price": "15.00",
      "category": "POTS"
    },
    {
      "id_produit": 52,
      "name": "POT TERRE 11 CM HAPPY POT",
      "price": "13.00",
      "category": "POTS"
    },
    {
      "id_produit": 53,
      "name": "POT TERRE 13 CM HAPPY POT",
      "price": "16.00",
      "category": "POTS"
    },
    {
      "id_produit": 54,
      "name": "POT TERRE 15 CM HAPPY POT",
      "price": "18.00",
      "category": "POTS"
    },
    {
      "id_produit": 55,
      "name": "POT TERRE 17 CM HAPPY POT",
      "price": "20.00",
      "category": "POTS"
    }
  ]
  );
  const [initData, setInitData] = useState(true);
  useEffect(() => {
    getProduits().then(result => {
      setProduit(result.data)
      console.log(result.data); // Access the data property of the result
    }).catch(error => {
      console.error('Error fetching data:', error);
    });
    
  }, [initData]);

  
  const [palmier, setPalmier] = useState([]);
  const [bouquets, setBouquets] = useState([]);
  const [agave, setAgave] = useState([]);
  const [pots, setPots] = useState([]);
  const [autres, setAutres] = useState([]);
  const [basket, setBasket] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState("Palmiers");
  const [selectedProduct, setSelectedProduct] = useState({
    "id_produit": 5,
    "name": "CHAMAEROPS PETIT",
    "price": "16.00",
    "category": "PALMIERS"
  });
  const [selectedQuantity, setSelectedQuantity] = useState(1);

  const categories = [
    { value: "Palmiers", content: "Palmiers" },
    { value: "Bouquets", content: "Bouquets" },
    { value: "Agave", content: "Agave" },
    { value: "Pots", content: "Pots" },
    { value: "Autres", content: "Autres" },
  ];

  const inputHandler = (e) => {
    const selectedCategory = e.target.value;
    setInputText(selectedCategory);
    if(selectedCategory==="Palmiers")
    {
        setSelectedProduct(palmier[0])
    }
    else if(selectedCategory==="Bouquets")
    {
        setSelectedProduct(bouquets[0])
    }
    else if(selectedCategory==="Agave")
    {
        setSelectedProduct(agave[0])
    }
    else if(selectedCategory==="Pots")
    {
        setSelectedProduct(pots[0])
    }
    else {
        setSelectedProduct(autres[0])
    }
    setSelectedCategory(selectedCategory);
    props.handleCallBack(selectedCategory);
  };

  function deleteProduct(productToDelete) {
    
    const indexToDelete = basket.findIndex((item) => item === productToDelete);
  if (indexToDelete !== -1) {
    const newBasket = [...basket];
    newBasket.splice(indexToDelete, 1);
    setBasket(newBasket);
    props.setMessageProduct(newBasket)
  }
}
  

  

  const inputProduit = (e) => {
    
    
    const selectedProductId = e.target.value;
    const selectedProduct = produit.find((item) => item.id_produit.toString() === selectedProductId);
    setSelectedProduct(selectedProduct);
  };
  

  const incrementQuantity = () => {
    setSelectedQuantity((prevQuantity) => prevQuantity + 1);
  };

  const decrementQuantity = () => {
    if (selectedQuantity > 1) {
      setSelectedQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  const handleValidation = () => {
    if(selectedProduct!==null){
        const newBasketItem = {
            product: selectedProduct,
            quantity: selectedQuantity,
          };
          
          setBasket((prevBasket) => [...prevBasket, newBasketItem]);
          props.setMessageProduct((prevBasket) => [...prevBasket, newBasketItem])
          setSelectedQuantity(1);
    }
    
  };

 

  useEffect(() => {
    // Update product categories
    const palmierProducts = produit.filter((item) => item.category === "PALMIERS");
    const bouquetsProducts = produit.filter((item) => item.category === "BOUQUETS");
    const agaveProducts = produit.filter((item) => item.category === "AGAVE");
    const potsProducts = produit.filter((item) => item.category === "POTS");
    const autresProducts = produit.filter((item) => item.category === "AUTRES");

    setPalmier(palmierProducts);
    setBouquets(bouquetsProducts);
    setAgave(agaveProducts);
    setPots(potsProducts);
    setAutres(autresProducts);
  }, [produit]);

  return (
    <div>
      <p className="contact-box-component">
        <label className="contact-textbox-label">Catégories</label>
        <select onChange={inputHandler} className="contact-textbox">
          {categories.map((item) => {
            return (
              <option key={item.value} className="contact-option" value={item.value}>
                {item.content}
              </option>
            );
          })}
        </select>
      </p>
      <p className="collect-box-component">
        <label className="contact-textbox-label">Produits</label>
        <select onChange={inputProduit} className="contact-textbox">
          {selectedCategory === "" ? (
            <option disabled>Séléctionnez une catégorie</option>
          ) : (
            <>
              {selectedProduct ? (
                <option className="option-produits"  key={selectedProduct.id_produit} value={selectedProduct.id_produit}>
                  {selectedProduct.name} - {selectedProduct.price} €
                </option>
              ) : (
                <option disabled>Séléctionnez un produit</option>
              )}
              {selectedCategory === "Palmiers" &&
                palmier
                  .filter((item) => item.id_produit !== selectedProduct?.id_produit)
                  .map((item) => {
                    return (
                      <option className="option-produits" key={item.id_produit} value={item.id_produit}>
                        {item.name} - {item.price} €
                      </option>
                    );
                  })}
              {selectedCategory === "Bouquets" &&
                bouquets
                  .filter((item) => item.id_produit !== selectedProduct?.id_produit)
                  .map((item) => {
                    return (
                      <option className="option-produits" key={item.id_produit} value={item.id_produit}>
                        {item.name} - {item.price} €
                      </option>
                    );
                  })}
              {selectedCategory === "Agave" &&
                agave
                  .filter((item) => item.id_produit !== selectedProduct?.id_produit)
                  .map((item) => {
                    return (
                      <option className="option-produits" key={item.id_produit} value={item.id_produit}>
                        {item.name} - {item.price} €
                      </option>
                    );
                  })}
              {selectedCategory === "Pots" &&
                pots
                  .filter((item) => item.id_produit !== selectedProduct?.id_produit)
                  .map((item) => {
                    return (
                      <option className="option-produits" key={item.id_produit} value={item.id_produit}>
                        {item.name} - {item.price} €
                      </option>
                    );
                  })}
              {selectedCategory === "Autres" &&
                autres
                  .filter((item) => item.id_produit !== selectedProduct?.id_produit)
                  .map((item) => {
                    return (
                      <option className="option-produits" key={item.id_produit} value={item.id_produit}>
                        {item.name} - {item.price} €
                      </option>
                    );
                  })}
            </>
          )}
        </select>
      </p>
      <div className="container-quantity">
        <label className="contact-textbox-label">Quantité</label>
        <div className="container-interact-validate">
          <p className="contact-box-component">
        
        <div className="quantity-input">
          <button className="button_plus" type="button" onClick={decrementQuantity}>
            -
          </button>
          <input className="input-number" type="number" value={selectedQuantity} onChange={() => {}} />
          <button className="button_plus" type="button" onClick={incrementQuantity}>
            +
          </button>
        </div>
      </p>
      
        <button className="validate-button" type="button" onClick={handleValidation}>
        Valider
      </button>
        </div>
      
      
      
      </div>
    

      {basket.length > 0 && (
        <div className="basket-container">
          <h2>Panier Total: {basket.reduce((total, item) => total + item.quantity * item.product.price, 0)} €</h2>
          {basket.map((item, index) => (
            <div className="container-product">
              <img className="delete-product-form" src={croix} onClick={()=> deleteProduct(item)}/>
               <div key={index} className="basket-item">
              
              <h4>Produit: {item.product.name}</h4>
                <p className="quantity-price-product"><span>Quantité: {item.quantity}    </span>  
                <span>  Prix: {item.quantity * item.product.price}€</span>
              </p>
            </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
