


export default function SelectionCards({nom, price, srcImg}){
    return <div className="card-selection">
    
        <img className="img-card-service" src={srcImg} alt="Logo" />
        <div className="text-service-card">
        <p className="nom-card-service">{nom}</p>
        <p className="price-card-service"> {price}</p>
        </div>
        
    </div>
}