import '../../css/BandeauImgActivity.css';

export default function BandeauImgActivity( props ) {
    // state
    const imgFond = props.imgFond;
    const titre = props.titre;
    // comportements

    // affichage (render)
    
    return (
        <section className="container-bandeau" >
            <img src={imgFond} alt="Fond Activité"/>
            <h1 className="activity-text"> {titre} </h1>
        </section>
    );
}
