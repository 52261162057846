import insta1 from "./insta1.jpg";
import insta2 from "./insta2.jpg";
import insta3 from "./insta3.jpg";
import insta4 from "./insta4.jpg";
import insta5 from "./insta5.jpg";
import insta6 from "./insta6.jpg";
import insta7 from "./insta7.jpg";
import insta8 from "./insta8.jpg";
import insta9 from "./insta9.jpg";

const images_insta = {
    insta1,
    insta2,
    insta3,
    insta4,
    insta5,
    insta6,
    insta7,
    insta8,
    insta9
}
export default images_insta;