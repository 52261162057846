import React, { useState, useEffect } from 'react';
import img4 from "../../../images/Abonnement/bouquetPerso1.jpg";
import img2 from "../../../images/Abonnement/bouquetPerso2.jpg";
import img3 from "../../../images/Abonnement/bouquetPerso3.jpg";
import img5 from "../../../images/Abonnement/bouquetPerso4.jpg";
import img1 from "../../../images/Abonnement/bouquet_aut40e1.jpg";
import img50 from "../../../images/Abonnement/Bouquet_05_10.jpg";
import img60 from "../../../images/Abonnement/Bouquet_06_30.jpg";
import img70 from "../../../images/Abonnement/Bouquet_07_10.jpg";
import img80 from "../../../images/Abonnement/Bouquet_08_10.jpg";
import "../../../css/ServicesCSS/SectionPerso.css"
import ArrowL from "../../../images/SVG/ARROW_L.svg"
import ArrowR from "../../../images/SVG/ARROW_R.svg"
import arrowMobileLight from "../../../images/SVG/arrowReverseCardLight.svg"
import arrowMobileDark from "../../../images/SVG/arrowReverseCardDark.svg"
import { getBouquetAboPerso } from "../../../lib";
export default function DemandePersonaliseSection(){

    const [isHoveredAbo, setIsHoveredAbo] = useState(false);
    const [indexBouquet, setIndexBouquet] = useState(0);
    const [indexAbo, setIndexAbo] = useState(0);
    const [displayBouquet, setDisplayBouquet] = useState(<img className="item-slider" src={img1} alt="decoration"/>);
    const [displayAbo, setDisplayAbo] = useState(<img className="item-slider" src={img5} alt="decoration"/>);
    const listBouquet = [<img className="item-slider" src={img1} alt="decoration"/>,
                        <img className="item-slider" src={img2} alt="decoration"/>,
                        <img className="item-slider" src={img3} alt="decoration"/>,
                        <img className="item-slider" src={img4} alt="decoration"/>,
                        <img className="item-slider" src={img5} alt="decoration"/>,];
    const listAbo = [<img className="item-slider" src={img50} alt="decoration"/>,
                    <img className="item-slider" src={img60} alt="decoration"/>,
                    <img className="item-slider" src={img70} alt="decoration"/>,
                    <img className="item-slider" src={img80} alt="decoration"/>];
    const [isHoveredBouquet, setIsHoveredBouquet] = useState(false);

    const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth > 780);

    //
    //let textPerso=["Les exemples ci-dessus ne vous conviennent pas ?","Vous voudriez encore plus gros ? plus petit ? sous une forme particulière ? en broche ou en diadème ? et pourquoi pas une pomme au milieu du bouquet ?","Envoyez-nous un message via le click and collect et concevez avec nous le fruit de vos idées les plus folles."]
    //let textAbonnement=["Nos bouquets vous ont plu et vous en voulez plus ?","Découvrez les différents abonnements adaptés à votre consommation et vos envies.","Et puis, 6 mois entouré de bouquets, si ce n’est pas la belle vie ça …"]
    const [textPerso, setTextPerso] = useState(["","",""]);
    const [textAbonnement, setTextAbonnement] = useState(["","",""]);
    const [initData, setInitData] = useState(true);
    
  useEffect(() => {
    getBouquetAboPerso().then(result => {
        const data_abonnement = result.data.find(evenement => evenement.libelle_section === "Abonnement_a_la_demande");
        const data_perso = result.data.find(evenement => evenement.libelle_section === "Bouquet_personnalise");
        console.log(data_perso);
        setTextPerso([data_perso.texte_1,data_perso.texte_2,data_perso.texte_3 ])
        setTextAbonnement([data_abonnement.texte_1,data_abonnement.texte_2,data_abonnement.texte_3])
      console.log(result.data);
       // Access the data property of the result
    }).catch(error => {
      console.error('Error fetching data:', error);
    });
    
  }, [initData]);
  
  
    //gestion du slider
    useEffect(() => {
    // Function to update isMobileScreen based on window.innerWidth
    const updateIsMobileScreen = () => {
      setIsMobileScreen(window.innerWidth > 780);
    };

    // Add a listener for the window resize event
    window.addEventListener('resize', updateIsMobileScreen);

    // Clean up the listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateIsMobileScreen);
    };
  }, []);
    const handleMouseEnterBouquet = () => {
        setIsHoveredBouquet(true);
    };
    const handleMouseLeaveBouquet = () => {
        setIsHoveredBouquet(false);
    };
    const handleMouseEnterAbo = () => {
        setIsHoveredAbo(true);
    };
    const handleMouseLeaveAbo = () => {
        setIsHoveredAbo(false);
    };
    function slideImageBouquet(value){
        if(indexBouquet+value > listBouquet.length-1){
           
            setDisplayBouquet(listBouquet[0])
            setIndexBouquet(0)
        }
        else if(indexBouquet+value < 0){
          
            setDisplayBouquet(listBouquet[listBouquet.length - 1])
            setIndexBouquet(listBouquet.length - 1)
        }
        else{
           
            setDisplayBouquet(listBouquet[indexBouquet + value])
            setIndexBouquet(indexBouquet + value)
        }
    }
    function slideImageAbo(value){
        if(indexAbo+value > listAbo.length-1){
           
            setDisplayAbo(listAbo[0])
            setIndexAbo(0)
        }
        else if(indexAbo+value < 0){
          
            setDisplayAbo(listAbo[listAbo.length - 1])
            setIndexAbo(listAbo.length - 1)
        }
        else{
           
            setDisplayAbo(listAbo[indexAbo + value])
            setIndexAbo(indexAbo + value)
        }
    }
    function handleAbo(){
        setIsHoveredAbo(!isHoveredAbo)
    }
    function handleBouquet(){
        setIsHoveredBouquet(!isHoveredBouquet)
    }
    


      const handleClickArrowAbo = (event) => {
        // Vérifier si l'élément cliqué a la classe "arrow-slider"
        // Si oui, éviter d'appeler handleAbo
        if (event.target.classList.contains('arrow-abo')) {
          return;
        }
    
        // Si l'élément cliqué n'a pas la classe "arrow-slider", alors appeler handleAbo
        handleAbo();
      };

      const handleClickArrowBouquet = (event) => {
        // Vérifier si l'élément cliqué a la classe "arrow-slider"
        // Si oui, éviter d'appeler handleAbo
        if (event.target.classList.contains('arrow-bouquet')) {
          return;
        }
    
        // Si l'élément cliqué n'a pas la classe "arrow-slider", alors appeler handleAbo
        handleBouquet();
      };

    return isMobileScreen ? <div className='section-personnalise'>
    <div className={`bouquet-perso-section ${isHoveredAbo ? 'bouquet-perso-hover' : ''}`}
    onMouseEnter={handleMouseEnterBouquet}
    onMouseLeave={handleMouseLeaveBouquet}>
            <div className='slider-container'>
                <h3 className='titre-section'>Bouquet personnalisé</h3>
            <div className='slider'> 
                <img className="arrow-slider" src={ArrowL} alt="decoration" onClick={()=> {slideImageBouquet(-1)}}></img>
                {displayBouquet}
                <img className="arrow-slider" src={ArrowR} alt="decoration" onClick={()=> {slideImageBouquet(1)}}></img>

            </div>
            </div>
            <div className={`section-text-perso ${isHoveredAbo ? "opacity-section": ""}`}> <p className='text-content-perso'>{"Les exemples ci-dessus ne vous conviennent pas ?\n\nVous voudriez encore plus gros ? plus petit ? sous une forme particulière ? en broche ou en diadème ? et pourquoi pas une pomme au milieu du bouquet ?\n\nEnvoyez-nous un message via le click and collect et concevez avec nous le fruit de vos idées les plus folles."}</p></div>
        </div>
        <div className={` abonnement-perso-section ${isHoveredBouquet ? 'abonnement-perso-hover' : ''}`}
    onMouseEnter={handleMouseEnterAbo}
    onMouseLeave={handleMouseLeaveAbo}
>
        <div className='slider-container'>
            <h3 className='titre-section'>Abonnement à la demande</h3>
            <div className='slider'>
                <img className="arrow-slider"  src={ArrowL} alt="decoration" onClick={()=> {slideImageAbo(-1)}}></img>
                {displayAbo}
                <img className="arrow-slider" src={ArrowR} alt="decoration" onClick={()=> {slideImageAbo(1)}}></img>

            </div>
            </div>
            <div className={`section-text-perso ${isHoveredBouquet ? "opacity-section": ""}`}> <p className='text-content-perso'>{"Nos bouquets vous ont plu et vous en voulez plus ?\n\nDécouvrez les différents abonnements adaptés à votre consommation et vos envies.\n\nEt puis, 6 mois entouré de bouquets, si ce n’est pas la belle vie ça …"}</p></div>
        </div>
    </div> :

<div className='section-personnalise'>
<div className={`bouquet-perso-section ${isHoveredBouquet ? 'bouquet-perso-hover-mobile' : ''}`}
onClick={handleClickArrowBouquet}>
        <div className='slider-container'>
            <div className='container-title-section-perso'>
            <img className='arrow-mobile' src={arrowMobileLight} alt="decoration" />
            <h3 className='titre-section'>Bouquet personnalisé</h3>
            </div>
            
        <div className='slider'> 
            <img className="arrow-slider arrow-bouquet" src={ArrowL} alt="decoration" onClick={()=> {slideImageBouquet(-1)}}></img>
            {displayBouquet}
            <img className="arrow-slider arrow-bouquet" src={ArrowR} alt="decoration" onClick={()=> {slideImageBouquet(1)}}></img>

        </div>
        </div>
        <div className={`section-text-perso ${isHoveredBouquet ? "opacity-section": ""}`}> <p className='text-content-perso'>{textPerso[0]+"\n\n"+textPerso[1]+"\n\n"+textPerso[2]}</p></div>
    </div>
    <div className={` abonnement-perso-section ${isHoveredAbo ? 'abonnement-perso-hover-mobile' : ''}`}
onClick={handleClickArrowAbo} 
>
    <div className='slider-container' >
        <div className='container-title-section-perso'>
            <img className='arrow-mobile' src={arrowMobileDark} alt="decoration" />
            <h3 className='titre-section'>Abonnement à la demande</h3>
        </div>
        <div className='slider'>
            <img className="arrow-slider arrow-abo"  src={ArrowL} alt="decoration" onClick={()=> {slideImageAbo(-1)}}></img>
            {displayAbo}
            <img className="arrow-slider arrow-abo" src={ArrowR} alt="decoration" onClick={()=> {slideImageAbo(1)}}></img>

        </div>
        </div>
        <div className={`section-text-perso ${isHoveredAbo ? "opacity-section": ""}`}> <p className='text-content-perso'>{textAbonnement[0]+"\n\n"+textAbonnement[1]+"\n\n"+textAbonnement[2]}</p></div>
    </div>
</div> 
}

/*<img src={img1} />
          <img src={img1} />
          <img src={img1} />
          <img src={img1} />*/