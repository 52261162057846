import fleur from '../../../images/SVG/FLOWER_LOGO.svg';

export default function AbonnementsBasiques(props){
    return (
        <div className="abonnements-card">
            <div className="flip-card">
                <div className="flip-card-front">
                    <img className="img-abonnements-card" src={props.srcImg}/>
                </div>
                <div className="flip-card-back">
                    {props.listTexts[0]}
                    {props.listTexts[1]}
                    {props.listTexts[2]}
                    {props.listTexts[3]}
                    <div className="backflip-svg-holder">
                        <img className='deco-backflip' src={fleur} alt="decoration"/>
                    </div>
                </div>
            </div>
        </div>
    )
}