import Bandeau from "../components/General/BandeauImgActivity";
import ContactForm from "../components/Contact/ContactForm";
import Links from "../components/General/Links";
import Path from "../components/General/Path";
import ScrollTopButton from "../components/General/ScrollTopButton";
import InfosPratiques from "../components/General/InfosPratiques";
import Copyright from "../components/General/Copyright";
import contact from "../images/Banniere/banniere6.jpg";
import Footer from "../components/General/FooterElements";


export default function ContactPage() {
    return (
        <div className="contact-page">
            <header>
                <Bandeau imgFond={contact} titre="Mentions Légales"/>
            </header>
            <main>
                <Path lastPage="Accueil" lastPagePath="/" page="Mentions Légales" pagePath="/MentionsLegalesPage"/>
                <div className="mentionsdiv">
                    <h1>MENTIONS LÉGALES</h1>
                    <h2>Informations légales</h2>
                    <p>Le présent site est la propriété de EARL FLEUR DE PALMIER, société civile spécialisée dans le secteur des activités de soutien aux cultures, immatriculée sous le SIREN 948886437, ayant son siège au 13 rue de l'Église, 50140 Romagny-Fontenay, France (téléphone : + 33 (0)6 77 91 72 58).</p>
                    <p>La société O2SWITCH, située à 63000 CLERMONT-FERRAND, au 29 rue Servan, 63000 Clermont-Ferrand, RCS de Clermont-Ferrand B 510 909 807 (téléphone : 04 44 44 60 40) assure l’hébergement du site web « https://www.fleurdepalmier.com/ ».</p>
                    <p>Tout internaute visitant ce site reconnaît avoir pris connaissance de la présente notice légale et s’engage à la respecter.</p>
                    <h2>Données collectées dans le cadre du formulaire de contact</h2>
                    <p>Les données à caractère personnel qui vous sont demandées sur le formulaire de contact sont nécessaires pour traiter votre demande. Elles sont destinées à la propriétaire de Fleur de Palmier, Annick Saives, et sont basées sur votre consentement. Ces données servent uniquement afin d'avoir un registre de clients et d'éviter à des programmes malveillants d'effectuer de fausses déclarations de demandes.</p>
                    <p>Vous bénéficiez d’un droit d’accès, de rectification, d’effacement, d’opposition, d’un droit à la portabilité des informations qui vous concernent ainsi que d’un droit à la limitation des traitements que vous pouvez exercer en adressant un courriel à fleurdepalmier50@gmail.com ou en adressant un courrier à l’adresse de la Palmeraie - 13 rue de l'Église - 50140 Romagny-Fontenay, en précisant vos coordonnées et en justifiant de votre identité par tout moyen. Vous disposez du droit de définir des directives générales et particulières définissant la manière dont vous entendez que soient exercés les droits susmentionnés en cas de décès. Vous avez le droit d’introduire une réclamation auprès de la Commission Nationale de l’Informatique et des Libertés (CNIL), autorité de contrôle en charge du respect des obligations en matière de données à caractère personnel.</p>
                    <p>Pour toute question relative au traitement de vos données à caractère personnel, vous pouvez nous contacter à l'adresse de la Palmeraie - 13 rue de l'Église - 50140 Romagny-Fontenay - ou par email à fleurdepalmier50@gmail.com.</p>
                </div>
            </main>
            <Footer/>
        </div>
    )
}