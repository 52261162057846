import FilterBar from "../components/Filters/FilterBar"
import ListCards from "../components/Cards/ListCards";
import Bandeau from "../components/General/BandeauImgActivity";

import Links from "../components/General/Links";
import ScrollTopButton from "../components/General/ScrollTopButton";
import InfosPratiques from "../components/General/InfosPratiques";
import Copyright from "../components/General/Copyright";
import Footer from "../components/General/FooterElements";

import {useState} from "react";

import contact from "../images/Banniere/banniere1.jpg";


export default function AnnuaireVegetalPage() {

    /*get filters values*/
    const [inputText, setInputText] = useState("");
    function getInputText(childData) {
        setInputText(childData)
        
    }
    const [filter1, setFilter1] = useState("");
    function getFilter1(childData) {
        setFilter1(childData)
        
    }
    const [filter2, setFilter2] = useState("");
    function getFilter2(childData) {
        setFilter2(childData)
        
    }
    const [onClickFilter, setonClickFilter] = useState("");
    function getonClickFilter(childData) {
        setonClickFilter(childData)
        
    }

    return (
        <div>
            <Bandeau imgFond={contact} titre="Annuaire Végétal"/>
            <div className="annuaire-vegetal">
                <FilterBar InputText={getInputText} Filter1={getFilter1} Filter2={getFilter2} onClickFilter={getonClickFilter}/>
                <ListCards InputText={inputText} Filter1={filter1} Filter2={filter2} onClickFilter={onClickFilter}/>
            </div>
            <Footer/>
        </div>
    )
}
