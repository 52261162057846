import React, { useState } from "react";
import { serviceDropdown } from "../../db/NavItems";
import { Link } from "react-router-dom";


export default function DropdownNavBar(props) {
  const [dropdown, setDropdown] = useState(false);

  return (
    <>
      <ul
        className="dropdown-item"
        onClick={() => setDropdown(!dropdown)}
      >
        {serviceDropdown.map((item) => {
          return (
            <li className="dropdown-content" key={item.id}>
              <Link className="link-nav-bar"
                to={item.path}
                onClick={() => {
                  props.fallback();
                  setDropdown(false);
                }}
              >
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
}

