export const NavItems = [
    {
      id: 3,
      title: "Accueil",
      path: "/",
      cName: "nav-item",
    },
    {
      id: 1,
      title: "Services",
      path: "",
      cName: "nav-item",
    },
    {
      id: 2,
      title: "Annuaire Végétal",
      path: "/annuaireVegetal",
      cName: "nav-item",
    },
    {
      id: 4,
      title: "Qui Sommes-Nous ?",
      path: "/aPropos",
      cName: "nav-item",
    },
    {
        id: 5,
        title: "Contact",
        path: "/contact",
        cName: "nav-item",
    },
  ];
  
  export const serviceDropdown = [
    {
      id: 1,
      title: "Nos compositions",
      path: "/abonnement",
      cName: "submenu-item",
    },
    {
      id: 2,
      title: "Prêt de plantes",
      path: "/pretDePlante",
      cName: "submenu-item",
    },
    {
      id: 3,
      title: "Événements",
      path: "/evenement",
      cName: "submenu-item",
    },
    
    
  ];