import '../../css/Links.css';

export default function Links() {
    // state

    // comportements

    // affichage (render)
    return (
        <section className="footer-links">
            <p>
                <span> Les Indispensables </span>
                <a href="/"> Accueil </a>
                <a href="/annuaireVegetal"> Annuaire Végétal </a>
                <a href="/contact"> Contact </a>
            </p>
            <p>
                <span> Services </span>
                <a href="/abonnement"> Nos Compositions </a>
                <a href="/pretDePlante"> Prêt de Plantes </a>
                <a href="/evenement"> Événements </a>
            </p>
            <p>
                <span> L'Histoire </span>
                <a href="/aPropos"> Qui sommes-nous ? </a>
                <a href="/aPropos/Annick"> Annick </a>
                <a href="/aPropos/Palmeraie"> La Palmeraie </a>
            </p>
            <p>
                <br></br>
                <a href="/aPropos/Vision"> Vision et Missions </a>
                <a href="/aPropos/Foret"> Mini Forêt </a>
                <a href="https://maps.app.goo.gl/Q77WeKNQBYQ3nuvD9?g_st=ic" target="_blank" rel="noreferrer"> Donnez votre avis  </a>
            </p>
        </section>
    );
}
