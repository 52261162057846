import Logo from  "../../images/Logo/logoHomeImg.png";
import MobileLogo from  "../../images/Logo/logo_mobile.svg";
import BackgroundImage from  "../../images/Background/BGHOME.jpg";
import MobileBackgroundImage from  "../../images/Background/HomescreenMOBIL2.jpg";
import React, { useState, useEffect } from "react";

export default function HomeScreen() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const backgroundImage = windowWidth < 480 ? MobileBackgroundImage : BackgroundImage;
  const logo = windowWidth < 480 ? MobileLogo : Logo;

    return <section className="home-screen-section">
        <img className="background-image" src={backgroundImage} alt="background home screen"/>
        <img className="logo-home-screen" src={logo} alt="logo"/>
            
    </section>
}