import StoryTextLeft from "../components/Storytelling/StoryTextLeft";
import StoryTextRight from "../components/Storytelling/StoryTextRight";
import StoryText from "../components/Storytelling/StoryText";
import Links from "../components/General/Links";
import ScrollTopButton from "../components/General/ScrollTopButton";
import InfosPratiques from "../components/General/InfosPratiques";
import Copyright from "../components/General/Copyright";
import svg1 from '../images/SVG/pinkShape.svg';
import svg2 from '../images/SVG/greenLines2.svg';
import svg3 from '../images/SVG/greenShape.svg';
import svg4 from '../images/SVG/pinkLines3.svg';
import svg5 from '../images/SVG/pinkBubbleFlat.svg';
import svg6 from '../images/SVG/greenLinesRound.svg';
import svg7 from '../images/SVG/greenBubbleSharp.svg';
import svg8 from '../images/SVG/pinkLinesSingle.svg';
import '../css/StoryCard.css';
import StoryPath from "../components/Storytelling/StoryPath";
import Footer from "../components/General/FooterElements";
import { getQSNPalmeraie } from "../lib";
import { useState, useEffect } from "react";
export default function AProposPalmeraiePage() {
    /*let dataText = [
        "Comment ça des palmiers en Normandie ?! Eh oui ! Nos palmiers sont produits dès la graine en Normandie, Ils supportent très bien le gel et vivent sans problème dans notre fameux climat tempéré Normand. Cela ressemble à une idée de génie de pouvoir produire de tel palmier et ainsi réduire le transport polluant venant du Sud, et pourtant c’est Christophe Le Chapelais qui, en 2007, à partir de simples graines issues d’un Trachycarpus Fortunei a commencé à faire pousser ces dernières dans son jardin.",
        "Ce palmier pousse originalement dans l’état de Yunnan en Chine, qui possède un climat similaire à l’ouest de la France. Il est appelé palmier chanvre car il possède une sorte de chanvre autour de son tronc qui lui permet de résister à des températures pouvant atteindre -18 à -20°C. Aujourd’hui, 16 ans plus tard, ce sont 2500 palmiers qui se développent dans le site préservé qu’est la Palmeraie Fleur de Palmier.",
        "D’autres espèces ayant évidemment rejoint notre palmier emblématique en cours de route : Yuccas, Agaves, Opuntia, Cordylines, agrumes et bien d’autres."
    ]*/

    const [dataText, setDataText] = useState(["","","",""]);
const [initData, setInitData] = useState(true);

  useEffect(() => {
    getQSNPalmeraie().then(result => {
      setDataText([result.data[0].texte_1,result.data[0].texte_2,result.data[0].texte_3])
      console.log(result.data); // Access the data property of the result
    }).catch(error => {
      console.error('Error fetching data:', error);
    });
  }, [initData]);
    return (
        <div className="a-propos-ss-page">
            <StoryPath page="La Palmeraie" pagePath="/aPropos/Palmeraie"/>
            <div className="a-propos-ss-page-computer">
                <div className="story-svg-holder">
                    <img className='deco-story-palm-1' src={svg1} alt="decoration"/>
                </div>
                <div className="story-svg-holder">
                    <img className='deco-story-palm-2' src={svg2} alt="decoration"/>
                </div>
                <span className="a-propos-sub-page-title-2">
                    La Palmeraie
                </span>
                <main>
                    <StoryTextLeft
                        cardText={dataText[0]}
                    />
                    <div className="story-svg-holder">
                        <img className='deco-story-palm-3' src={svg3} alt="decoration"/>
                    </div>
                    <div className="story-svg-holder">
                        <img className='deco-story-palm-4' src={svg4} alt="decoration"/>
                    </div>
                    <StoryTextRight
                        cardText={dataText[1]}
                    />
                    <div className="story-svg-holder">
                        <img className='deco-story-palm-5' src={svg5} alt="decoration"/>
                    </div>
                    <div className="story-svg-holder">
                        <img className='deco-story-palm-6' src={svg6} alt="decoration"/>
                    </div>
                    <StoryTextLeft
                        cardText={dataText[2]}
                    />
                    <div className="story-svg-holder">
                        <img className='deco-story-palm-7' src={svg7} alt="decoration"/>
                    </div>
                    <div className="story-svg-holder">
                        <img className='deco-story-palm-8' src={svg8} alt="decoration"/>
                    </div>
                </main>
            </div>
            <div className="a-propos-ss-page-phone">
                <main>
                    <StoryText
                        titleText="La Palmeraie"
                        cardText={dataText[0]+" "+dataText[1]+" "+dataText[2]}
                    />
                </main>
            </div>
            <Footer/>
        </div>
    )
}