
export default function ContainerEvenement(props) {
    return (
       
            <div className='contenu-actu'>
            <h2 className='sous-titre-slider'> {props.textEvnmt}</h2>
            <img className= "img-actu" src={props.imgActu} alt="decoration"/>
            </div>
           
    );
}


