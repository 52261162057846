import Bandeau from "../components/General/BandeauImgActivity";
import Links from "../components/General/Links";
import Path from "../components/General/Path";
import ScrollTopButton from "../components/General/ScrollTopButton";
import InfosPratiques from "../components/General/InfosPratiques";
import Copyright from "../components/General/Copyright";
import contact from "../images/Banniere/banniere3.jpg";
import CollectionServicesPret from "../components/services/Sections/CollectionsPret"
import DemandePersonaliseSection from "../components/services/Sections/DemandePersonaliseSection"
import Footer from "../components/General/FooterElements";

export default function PretDePlantePage() {
    return (
            <div className="pret-plante-page">
                <Bandeau imgFond={contact} titre="Prêt de Plantes"/>
                <main className="page-pret">
                    <Path lastPage="Accueil" lastPagePath="/" page="Prêt de Plantes" pagePath="/pretDePlante"/>
                    <CollectionServicesPret/>
                    
                    <DemandePersonaliseSection/>
                </main>
                <Footer/>
            </div>
    )
}