import StoryTextLeft from "../components/Storytelling/StoryTextLeft";
import StoryTextRight from "../components/Storytelling/StoryTextRight";
import StoryText from "../components/Storytelling/StoryText";
import Links from "../components/General/Links";
import ScrollTopButton from "../components/General/ScrollTopButton";
import InfosPratiques from "../components/General/InfosPratiques";
import Copyright from "../components/General/Copyright";
import svg1 from '../images/SVG/greenShapeAdjusted.svg';
import svg2 from '../images/SVG/pinkLines2.svg';
import svg3 from '../images/SVG/pinkShapeAdjusted.svg';
import svg4 from '../images/SVG/greenLines3.svg';
import svg5 from '../images/SVG/greenBubbleFlat.svg';
import svg6 from '../images/SVG/pinkLinesRound.svg';
import svg7 from '../images/SVG/pinkBubbleSharp.svg';
import svg8 from '../images/SVG/greenLinesSingle.svg';
import '../css/StoryCard.css';
import StoryPath from "../components/Storytelling/StoryPath";
import Footer from "../components/General/FooterElements";
import { data } from "jquery";
import { getQSNVisionMissions } from "../lib";
import { useState, useEffect } from "react";
export default function AProposVisionPage() {
    /*let dataText = [
        "Cela faisait déjà plusieurs années que je m’intéressais aux bouquets et aux créations végétales, mais surtout que je partageais des bouquets personnalisés sur les réseaux avec mes amis pendant le COVID, puis en vrai. Au moment de choisir d’aller vers une reconversion, ma passion pour ce secteur fut évidemment un argument de poids : l’idée de pouvoir proposer des bouquets et plantes produits localement contrastant complétement avec l’industrie actuelle m’a attiré (savez vous que 85% des fleurs coupées en France proviennent de l’étranger ?).",
        "C’est pourquoi Fleur de Palmier est fier de proposer des services limitant l’impact environnemental comme la coupe de fleurs locales évidemment moins polluante que les bouquets importés des pays européens, voir plus loin …ou éviter aux normands de devoir acheter des palmiers achetés en Italie ou Espagne en leur proposant de se fournir localement grâce à des plantes adaptés à notre climat.",
        "Commencer cette aventure m’a également faire réaliser toutes les possibilités que les plantes et fleurs nous offrent. Avec ce projet, je veux offrir une alternative végétale à tous les moments de la vie, de la naissance au trépas, le tout avec un impact carbone le plus restreint possible en favorisant le circuit court sur la région normande. L’idée est même de pouvoir livrer dans un rayon de 20 km en vélo électrique les bouquets."
    ]*/

    const [dataText, setDataText] = useState(["","","",""]);
const [initData, setInitData] = useState(true);

  useEffect(() => {
    getQSNVisionMissions().then(result => {
      setDataText([result.data[0].texte_1,result.data[0].texte_2,result.data[0].texte_3])
      console.log(result.data); // Access the data property of the result
    }).catch(error => {
      console.error('Error fetching data:', error);
    });
  }, [initData]);

    return (
        <div className="a-propos-ss-page">
            <StoryPath page="Vision et Missions" pagePath="/aPropos/Vision"/>
            <div className="a-propos-ss-page-computer">
                <div className="story-svg-holder">
                    <img className='deco-story-palm-1' src={svg1} alt="decoration"/>
                </div>
                <div className="story-svg-holder">
                    <img className='deco-story-palm-2' src={svg2} alt="decoration"/>
                </div>
                <span className="a-propos-sub-page-title-1">
                    Vision et Missions
                </span>
                <main>
                    <StoryTextLeft
                        cardText={dataText[0]}
                    />
                    <div className="story-svg-holder">
                        <img className='deco-story-palm-3' src={svg3} alt="decoration"/>
                    </div>
                    <div className="story-svg-holder">
                        <img className='deco-story-palm-4' src={svg4} alt="decoration"/>
                    </div>
                    <StoryTextRight
                        cardText={dataText[1]}
                    />
                    <div className="story-svg-holder">
                        <img className='deco-story-palm-5' src={svg5} alt="decoration"/>
                    </div>
                    <div className="story-svg-holder">
                        <img className='deco-story-palm-6' src={svg6} alt="decoration"/>
                    </div>
                    <StoryTextLeft
                        cardText={dataText[2]}
                    />
                    <div className="story-svg-holder">
                        <img className='deco-story-palm-7' src={svg7} alt="decoration"/>
                    </div>
                    <div className="story-svg-holder">
                        <img className='deco-story-palm-8' src={svg8} alt="decoration"/>
                    </div>
                </main>
            </div>
            <div className="a-propos-ss-page-phone">
                <main>
                    <StoryText
                        titleText="Vision et Missions"
                        cardText={dataText[0]+" "+dataText[1]+" "+dataText[2]}
                        />
                </main>
            </div>
            <Footer/>
        </div>
    )
}