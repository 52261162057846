export default function ScrollTopButton() {
    // state

    // comportements
    const topFunction = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    // affichage (render)
    return (
        <button className="scrollTop-contact" onClick={() => topFunction()} id="myBtn" title="Go to top">↑</button>
    );
}
