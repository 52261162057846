import { Link } from "react-router-dom";
import React, { useState } from "react";
export default function Activity({ activityImage,  buttonText, title, listTextsActivity, side, color, path }) {
    // state
    const [clicked, setClicked] = useState(false)
    // comportements
    function handleClick() {
        setClicked(!clicked);
      }
    // affichage (render)
    return (
        <section className={ `${side ? "left-activity-section" : "right-activity-section"} ${clicked ? "mb-clicked-activity" : "mb-unclicked-activity"}`} onClick={handleClick}>
            <img src={activityImage} className="img-activity-section" alt="activité"/>
            <Link className="link-activity-section" to={path}>
            
                <button className="button-activity-section">
                    {buttonText}
                </button>
            
            </Link>
            <div className={ color===1 ? "section-text-activity activity1" : color===2? "section-text-activity activity2" : "section-text-activity activity3"}  >
                <h2>{title}</h2>
                {listTextsActivity.map((text) => (
                    <h3 key={text.content}>
                        {text}
                    </h3>
                ))}
                
            </div>
        </section>
    );
}