import StoryTextLeft from "../components/Storytelling/StoryTextLeft";
import StoryTextRight from "../components/Storytelling/StoryTextRight";
import StoryText from "../components/Storytelling/StoryText";
import Links from "../components/General/Links";
import ScrollTopButton from "../components/General/ScrollTopButton";
import InfosPratiques from "../components/General/InfosPratiques";
import Copyright from "../components/General/Copyright";
import svg1 from '../images/SVG/greenShapeAdjusted.svg';
import svg2 from '../images/SVG/pinkLines2.svg';
import svg3 from '../images/SVG/pinkShapeAdjusted.svg';
import svg4 from '../images/SVG/greenLines3.svg';
import svg5 from '../images/SVG/greenBubbleFlat.svg';
import svg6 from '../images/SVG/pinkLinesRound.svg';
import '../css/StoryCard.css';
import StoryPath from "../components/Storytelling/StoryPath";
import Footer from "../components/General/FooterElements";
import { getQSNAnnick } from "../lib";
import { useState, useEffect } from "react";
export default function AProposAnnickPage() {
    /*let dataText = ["Mais qu’est ce que t’es venue foutre au fin fond de la Normandie pour faire pousser des palmiers ? » Question récurrente car c’est un virage à 180°C qui s’est ouvert à moi début 2022. Après 20 ans dans l’industrie pharmaceutique et les allers-retours entre bureaux parisiens et la Normandie, je ne pouvais laisser passer l’opportunité de me créer un coin de paradis les pieds en éventail sous les palmiers et le soleil normand.",
    "Je me présente donc : Annick (intervenante cirque au sein du troupe d’artiste du Périgord, les fans du Flambeau comprendront 😊) Saives, vétérinaire de formation et fraichement devenue propriétaire de Fleur de Palmier, avec une seule envie : vous accueillir au sein de mon jardin inattendu.", ""
    ]*/

    const [dataText, setDataText] = useState(["","","",""]);
const [initData, setInitData] = useState(true);

  useEffect(() => {
    getQSNAnnick().then(result => {
      setDataText([result.data[0].texte_1,result.data[0].texte_2,result.data[0].texte_3])
      console.log(result.data); // Access the data property of the result
    }).catch(error => {
      console.error('Error fetching data:', error);
    });
  }, [initData]);
    return (
        <div className="a-propos-ss-page">
            <StoryPath page="Annick" pagePath="/aPropos/Annick"/>
            <div className="a-propos-ss-page-computer">
                <div className="story-svg-holder">
                    <img className='deco-story-palm-1' src={svg1} alt="decoration"/>
                </div>
                <div className="story-svg-holder">
                    <img className='deco-story-palm-2' src={svg2} alt="decoration"/>
                </div>
                <span className="a-propos-sub-page-title-1">
                    Annick
                </span>
                <main>
                    <StoryTextLeft
                        cardText={dataText[0]}
                    />
                    <div className="story-svg-holder">
                        <img className='deco-story-palm-3' src={svg3} alt="decoration"/>
                    </div>
                    <div className="story-svg-holder">
                        <img className='deco-story-palm-4' src={svg4} alt="decoration"/>
                    </div>
                    <StoryTextRight
                        cardText={dataText[1]}
                    />
                    <div className="story-svg-holder">
                        <img className='deco-story-palm-5' src={svg5} alt="decoration"/>
                    </div>
                    <div className="story-svg-holder">
                        <img className='deco-story-palm-6' src={svg6} alt="decoration"/>
                    </div>
                </main>
            </div>
            <div className="a-propos-ss-page-phone">
                <main>
                    <StoryText
                        titleText="Annick"
                        cardText={dataText[0]+" "+dataText[1]+" "+dataText[2]}
                    />
                </main>
            </div>
            <Footer/>
        </div>
    )
}



