import TextBox from "../Contact/TextBox";
import TextBoxChoices from "../Contact/TextBoxChoices";
import MessageBox from "../Contact/MessageBox";
import '../../css/ContactForm.css';
import React, { useState } from "react";
import {postClient} from "../../lib"
import { Axios } from "../../config";
import { useEffect } from "react";
import svg1 from '../../images/SVG/pinkBubbleSharp.svg';
import svg2 from '../../images/SVG/purpleShape.svg';
import svg3 from '../../images/SVG/purpleLines3.svg';
import svg4 from '../../images/SVG/greenLines.svg';
import svg5 from '../../images/SVG/greenShapeHollow.svg';
import svg6 from '../../images/SVG/pinkShapeHollow.svg';
import "../../css/Contact.css"

export default function ContactForm() {
       
      

    // comportements
    const [gender, setGender] = useState("M");
    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [message, setMessage] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [isEmailSent, setIsEmailSent] = useState(true);
    const [object, setObject] = useState("Information Générale");
 


    const formGender = [{ value: "M", content: "Monsieur" }, { value: "F", content: "Madame" }, { value: "O", content: "Autre" }];
    const formObject = [{ value: "Information Générale", content: "Information Générale" }, { value: "Anomalie Internet", content: "Anomalie Internet" }, { value: "Reclamation", content: "Réclamation" }, { value: "Autre", content: "Autre" }];
    /*-----------------------------------*/

    function getNameInput(childData) {
        setName(childData)
        
    }
    function getGenderInput(childData) {
        setGender(childData)
    }
    function getLastNameInput(childData) {
        setLastName(childData)
    }
    function getEmailInput(childData) {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        setIsValidEmail(emailRegex.test(childData));
        if(isValidEmail){
            setEmail(childData)
        }
        else{setEmail("")}
        
    }
    function getAddressInput(childData) {
        setAddress(childData)
    }
    function getCityInput(childData) {
        setCity(childData)
    }
    function getZipCodeInput(childData) {
        setZipCode(childData)
    }
    function getMessageInput(childData) {
        setMessage(childData)
        
    }
    function getObjectInput(childData) {
        setObject(childData)
    }
    
    const SubmitData =  () => {
        setIsEmailSent(false)
        const postData = {
            email: email,
            name: name,
            last_name: lastName,
            gender: gender,
            address: address,
            city: city,
            zip_code: zipCode, 
            message: message
          };
        postClient(postData);
      };
     
      
      
               const Mailto = ({ email, subject = '', body = '', children }) => {
                    let params = (subject || body) ? '?' : '';
                    if (subject) params += `subject=${encodeURIComponent(subject)}`;
                    if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}%0D%0A%0D%0A`;
                  if(isValidEmail){
                    return <a href={`mailto:${email}${params}`}>{children}</a>;
                  }else return <div>{children}</div>;
        }

      /*          
const Mailto = ({ email, subject = '', body = '', children }) => {
    try {
        
        let params = (subject || body) ? '?' : '';
        if (subject) params += `subject=${encodeURIComponent(subject)}`;
        if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}%0D%0A%0D%0A`;
      if(isValidEmail){
        
        return <a href={`mailto:${email}${params}`}>{children}</a>;
      }else return <div>{children}</div>;
    } catch (error) {
      console.error("Erreur lors de la création du lien mailto:", error);
      return <div>{children}</div>;
    }
  };*/

                 

    // affichage (render)
    return (
        <section className="contact-section">
            
                    <img className='contact-story-page-1' src={svg1} alt="decoration"/>
              
                    <img className='contact-story-page-2' src={svg2} alt="decoration"/>
                    <img className='contact-story-page-5' src={svg5} alt="decoration"/>
                    <img className='contact-story-page-3' src={svg3} alt="decoration"/>
                    <img className='contact-story-page-4' src={svg4} alt="decoration"/>
               
                    
               
                    <img className='contact-story-page-6' src={svg6} alt="decoration"/>
             
                
                
            <div className="contact-center-element">
                <p className="contact-text-lead">
                    Contactez la Palmeraie par téléphone : 06.77.91.72.58 (prix d'un appel local)
                    <br/>
                    Ou par mail à l'adresse : fleurdepalmier50@gmail.com
                </p>
                <p className="contact-text-lead-2">Ou envoyez un message :</p>
                <div className="contact-form-background">
                    <div className="contact-content">
                        <form className="contact-column">
                            <TextBoxChoices title="Civilité" itemsList={formGender} handleCallBack={getGenderInput} />

                            <TextBox
                                title="Nom *"
                                handleCallBack={getLastNameInput}
                            />
                            <TextBox
                                title="Prénom *"
                                handleCallBack={getNameInput}
                            />
                            <TextBox
                                title="Email *"
                                handleCallBack={getEmailInput}
                            />
                            {isValidEmail && <p className="valid-email-text">L'adresse e-mail est valide.</p>}
                            {!isValidEmail && <p className="invalid-email-text">L'adresse e-mail n'est pas valide.</p>}
                            <TextBox
                                title="Adresse"
                                handleCallBack={getAddressInput}
                            />
                            <TextBox
                                title="Ville"
                                handleCallBack={getCityInput}
                            />
                            <TextBox
                                title="Code postal"
                                handleCallBack={getZipCodeInput}
                            />
                        </form>
                        <form className="contact-column">
                            <TextBoxChoices
                                title="Objet *"
                                itemsList={formObject}
                                handleCallBack={getObjectInput}
                            />

                            <MessageBox
                                title="Message *"
                                handleCallBack={getMessageInput}
                            />
                        </form>
                    </div>
                    <div className="contact-button-content-center">
                        <div className="contact-button-content">
                            <span>* Champs obligatoires</span>
                            {isEmailSent ? 
                                <button className="contact-button" onClick={SubmitData}>
                                    Envoyer
                                </button> 
                            :
                            <button className="contact-button-clicked" >
                                    Envoyé
                                </button> 
                        }   
                        </div>
                    </div>
                </div>
            </div>
       
                    
                
        </section>
    );
}