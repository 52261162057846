import React, { useEffect, useState } from 'react';

import chamerops from "../../../images/Abonnement/pretchamaeropsHumi.jpg"
import cyccas from "../../../images/Abonnement/pretcyccas.jpg"
import phoenix from "../../../images/Abonnement/pretphoenix.jpg"
import trachycarpus from "../../../images/Abonnement/prettrachycarpusFor.jpg"

import SelectionCards from "../Cards/SelectionCards";
import { getPretPlantes } from "../../../lib";


import useEmblaCarousel from 'embla-carousel-react'

export default function CollectionServicesPret() {
  const [isMobile, setIsMobile] = useState(false);

  const [cardData, setCardData] = useState([
    {
      "texte_pret": "Trachycarpus Fortunei",
      "prix_pret": "prix sur consultation",
    },
    {
      "texte_pret": "Phoenix",
      "prix_pret": "prix sur consultation",
    },
    {
      "texte_pret": "Cyccas",
      "prix_pret": "prix sur consultation",
    },
    {
      "texte_pret": "Chamaerops Humilis",
      "prix_pret": "prix sur consultation",
    },

  ]);
  const [initData, setInitData] = useState(true);
  useEffect(() => {
    getPretPlantes().then(result => {
      setCardData(result.data)
      console.log(result.data); // Access the data property of the result
    }).catch(error => {
      console.error('Error fetching data:', error);
    });
    
  }, [initData]);
/*
  let cardData = [
    {
      "nom": "Trachycarpus Fortunei",
      "prix": "prix sur consultation",
    },
    {
      "nom": "Phoenix",
      "prix": "prix sur consultation",
    },
    {
      "nom": "Cyccas",
      "prix": "prix sur consultation",
    },
    {
      "nom": "Chamaerops Humilis",
      "prix": "prix sur consultation",
    },

  ]
*/
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 781);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [emblaRef] = useEmblaCarousel()

  return (
    <div className="section-card-service">
      <h1 className="titre-collection">Nos plantes à votre service</h1>
      <h2 className="sous-titre-collection">Découvrez notre collection de fleurs et de plantes disponibles pour égayer votre événement</h2>
      <h2 className="sous-titre-collection-2">Demandez vos plantes via l'onglet Click&Collect disponible sur cette page !</h2>
      {isMobile ? (
        
        <div className="embla" ref={emblaRef}>
      <div className="embla__container">
          <SelectionCards nom={cardData[0]["texte_pret"]} price={cardData[0]["prix_pret"]} srcImg={trachycarpus} />
          <SelectionCards nom={cardData[1]["texte_pret"]} price={cardData[1]["prix_pret"]} srcImg={phoenix} />
          <SelectionCards nom={cardData[2]["texte_pret"]} price={cardData[2]["prix_pret"]} srcImg={cyccas} />
          <SelectionCards nom={cardData[3]["texte_pret"]} price={cardData[3]["prix_pret"]} srcImg={chamerops} />
          </div>
    </div>
        
      ) : (
        <div className="card-selection-section">
          <SelectionCards nom={cardData[0]["texte_pret"]} price={cardData[0]["prix_pret"]} srcImg={trachycarpus} />
          <SelectionCards nom={cardData[1]["texte_pret"]} price={cardData[1]["prix_pret"]} srcImg={phoenix} />
          <SelectionCards nom={cardData[2]["texte_pret"]} price={cardData[2]["prix_pret"]} srcImg={cyccas} />
          <SelectionCards nom={cardData[3]["texte_pret"]} price={cardData[3]["prix_pret"]} srcImg={chamerops} />
          </div>
      )}
    </div>
  );
}