import Bandeau from "../components/General/BandeauImgActivity";
import ContactForm from "../components/Contact/ContactForm";
import Links from "../components/General/Links";
import Path from "../components/General/Path";
import ScrollTopButton from "../components/General/ScrollTopButton";
import InfosPratiques from "../components/General/InfosPratiques";
import Copyright from "../components/General/Copyright";
import contact from "../images/Banniere/banniere6.jpg";
import Footer from "../components/General/FooterElements";


export default function ContactPage() {
    return (
        <div className="contact-page">
            <header>
                <Bandeau imgFond={contact} titre="Contact"/>
            </header>
            <main>
                <Path lastPage="Accueil" lastPagePath="/" page="Contact" pagePath="/contact"/>
                <ContactForm/>
            </main>
            <Footer/>
        </div>
    )
}