import {useState} from "react";

export default function TextBoxChoices(props) {

    const [inputText, setInputText] = useState("");

    // comportements
    let inputHandler = (e) => {
        //convert input text to lower case
        var lowerCase = e.target.value.toLowerCase();
        setInputText(lowerCase);
        props.handleCallBack(lowerCase);
        };
    // affichage (render)
    return (
        <p className="contact-box-component">
            <label className="contact-textbox-label"> {props.title} </label>
            <select onChange={inputHandler} className="contact-textbox" >
            {props.itemsList.map((item) => {
          return (
            <option key={item.value} className="contact-option" value={item.value}> {item.content}</option>
          );
        })}
            </select>
            
        </p>
    );
}
