import AbonnementsBasiques from "../Cards/AbonnementsBasiques"

import grosImg from "../../../images/Abonnement/CARTE_ABO_BIG.jpg"
import midImg from "../../../images/Abonnement/CARTE_ABO_mid.jpg"
import ptitImg from "../../../images/Abonnement/CARTE_ABO_low.jpg"

export default function AbonnementsSection(){
    const textAbonnement6b = [<h3>Un <span>petit abonnement</span> pour des envies de bouquets occasionelles !</h3>,<p>Cette offre vous permet de commander <h2>6 bouquets de fleurs</h2> au choix et est valable pendant <span>6 mois</span></p>,<p>Idéal pour offrir !</p>,<h2>Prix d'achat : 60€</h2>];
    const textAbonnement9b = [<h3>Un <span>abonnement intermédiaire</span> pour offrir à tous ses proches !</h3>,<p>Cette offre vous permet de commander <h2>9 bouquets de fleurs</h2> au choix et est valable pendant <span>6 mois</span></p>,<p>Parfait pour partager !</p>,<h2>Prix d'achat : 80€</h2>];
    const textAbonnement12b = [<h3>Un <span>grand abonnement</span> pour des fleurs de partout !</h3>,<p>Cette offre vous permet de commander <h2>12 bouquets de fleurs</h2> au choix et est valable pendant <span>6 mois</span></p>,<p>Il y en aura pour tout le monde !</p>,<h2>Prix d'achat : 100€</h2>];

    return (
        <div className="section-abonnements-basiques">
            <h1 className="titre-abonnements-basiques">Des abonnements pour nos bouquets !</h1>
            <div className="container-abonnements-basiques">
                <AbonnementsBasiques listTexts={textAbonnement6b} srcImg={ptitImg}/>
                <AbonnementsBasiques listTexts={textAbonnement9b} srcImg={midImg}/>
                <AbonnementsBasiques listTexts={textAbonnement12b} srcImg={grosImg}/>
            </div>
            <h1 className="titre-abonnements-basiques-2">Commandez vos abonnements en envoyant un message avec ce que vous souhaitez via l'onglet Click&Collect disponible sur cette page ou via la page de contact !</h1>
        </div>
    )
}