import "../../../css/ServicesCSS/EvenementSection.css"

export default function EvenementSection2(props){

    return (
        <section className='section-evenement2'>
            <div className='container-titre-evenmenement'><h2 className='titre-evenmenement'>{props.title2}</h2></div>
            <div className='contenu'>
                <p className='text-evenement'>{props.text2}</p>
                <img className="img-evenement" src={props.src2} alt="decoration"/>
            </div>
        </section>
    )
}
