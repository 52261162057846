import SlideShow from "../components/Cards/Slide";
import SliderInstagram from "../components/Cards/SliderInstagram";
import Activity from "../components/General/Activity";
import HomeScreen from "../components/General/HomeScreen";
import imgAbonnement from "../images/Background/imgActivityAbonnement.jpg";
import imgPret from "../images/Background/PRET.jpg";
import imgTombe from "../images/Background/TombePaysagere.jpg";
import imgProjetPedag from "../images/Background/MiniForet.jpg";
import Footer from "../components/General/FooterElements";
import Map from "../components/General/SectionMap.jsx";
import DemandePersonaliseSection from "../components/services/Sections/DemandePersonaliseSection"
import PastillePrix from "../components/General/PastillePrix";

import "../css/accueilCSS/sectionActivity.css"

export default function HomePage() {
    const textAbonnement = ["Venez découvrir nos différentes options d'abonnements aux bouquets originaux conçus avec les fleurs qui ont grandi à la Palmeraie !","Les fleurs sont cueillies le jour même pour la conception de votre bouquet afin que celui-ci soit le plus frais et beau possible !"];
    const textPret = ["Besoin de fleurs pour une occasion ou d'une allée de palmiers pour une photo tropicale ?","Venez nous empreinter tout le nécessaire, c'est ici que ça se passe !"];
    const textTombe = ["Mariages, tombe et bouquets funéraires, projets pédagogiques, composition paysagère ou tout simplement demande spéciale. Tous vos besoins particuliers et moments forts sont à retrouver dans notre section Evènements."];
    const textForet = ["Je suis avant tout engagée dans une démarche orientée vers l'avenir, et c'est pourquoi la Palmeraie est en relation avec des écoles afin de permettre aux plus jeunes d'apprendre tôt l'importance des plantes qui nous entourent","Si le concept de mini-forêt ne vous est pas familié, vous pouvez vous informer davantage ici !"];

    return (
        <div className="page">
            <PastillePrix />
            <HomeScreen />
            <DemandePersonaliseSection/>
            <SlideShow />
            <Activity activityImage={imgAbonnement}  buttonText="Découvrir" title="NOS COMPOSITIONS" listTextsActivity={textAbonnement} side={true} color={1} path="/abonnement"/>
            <Activity activityImage={imgPret}  buttonText="Découvrir" title="PRÊT DE PLANTES" listTextsActivity={textPret} side={false} color={2} path="/pretDePlante" />
            <Activity activityImage={imgTombe}  buttonText="Découvrir" title="ÉVÈNEMENTS" listTextsActivity={textTombe} side={true} color={3} path="/evenement" />
            <Activity activityImage={imgProjetPedag}  buttonText="Découvrir" title="PROJET DE MINI-FORÊT" listTextsActivity={textForet} side={false} color={2} path="/aPropos/Foret" />
            <SliderInstagram/>
            <Map/>
            <Footer/>
        </div>
    )
}