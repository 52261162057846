import Bandeau from "../components/General/BandeauImgActivity";
import Links from "../components/General/Links";
import Path from "../components/General/Path";
import ScrollTopButton from "../components/General/ScrollTopButton";
import InfosPratiques from "../components/General/InfosPratiques";
import Copyright from "../components/General/Copyright";
import contact from "../images/Banniere/banniere4.jpg";
import CollectionServices from "../components/services/Sections/Collections"
import AbonnementsSection from "../components/services/Sections/AbonnementsSection"
import DemandePersonaliseSection from "../components/services/Sections/DemandePersonaliseSection"
import SectionPerso from "../components/services/Sections/SectionPerso"
import Footer from "../components/General/FooterElements";

export default function AbonnementPage() {
    return (
            <div className="abonnement-page">
                <Bandeau imgFond={contact} titre="Nos Compositions"/>
                <main className="page-abonnement">
                    <Path lastPage="Accueil" lastPagePath="/" page="Nos Compositions" pagePath="/abonnement"/>
                    <CollectionServices/>
                    <AbonnementsSection/>
                    <SectionPerso/>
                    <DemandePersonaliseSection/>
                </main>
                <Footer/>
            </div>
    )
}