import '../../css/InfosPratiques.css';

export default function InfosPratiques() {
    // state

    // comportements

    // affichage (render)
    return (
        <section className="infos-pratiques">
            <p>
                <span className="infos-pratiques-title"> Informations pratiques </span>
            </p>
            <ul className="infos-pratiques-ul-computer">
                <li>
                    <p>
                        <span>Parcelle Fleurie et Magasin de vente</span>
                        <span>Ouverture au public : 16h-18h30</span>
                        <span>Tous les jours sauf le Dimanche</span>
                        <span>Autres horaires sur demande par téléphone</span>
                    </p>
                    <p>
                        <span>13 rue de l'Église</span>
                        <span>50140 Romagny-Fontenay</span>
                        <span>France</span>
                    </p>
                </li>
                <li>
                    <p>
                        <span>Site de production / Palmeraie</span>
                        <span>Visite sur rendez-vous</span>
                        <span>Semaine et Week-ends</span>
                    </p>
                    <p>
                        <span>Chenilly</span>
                        <span>50140 Mortain Bocage</span>
                        <span>France</span>
                    </p>
                </li>
                <li>
                    <p>
                        <span>Numéro de téléphone :</span>
                        <span>06 77 91 72 58</span>
                        <span>(prix d'un appel local)</span>
                    </p>
                    <p>
                        <span>Adresse Mail :</span>
                        <span>fleurdepalmier50@gmail.com</span>
                    </p>
                </li>
            </ul>
            <div className="infos-pratiques-ul-phone">
                <ul>
                    <li>
                        <p>
                            <span>Parcelle Fleurie et Magasin de vente</span>
                            <span>Ouverture au public : 16h-18h30</span>
                            <span>Tous les jours sauf le Dimanche</span>
                            <span>Autres horaires sur demande par téléphone</span>
                        </p>
                        <p>
                            <span>13 rue de l'Église</span>
                            <span>50140 Romagny-Fontenay</span>
                            <span>France</span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>Site de production / Palmeraie</span>
                            <span>Visite sur rendez-vous</span>
                            <span>Semaine et Week-ends</span>
                        </p>
                        <p>
                            <span>Chenilly</span>
                            <span>50140 Mortain Bocage</span>
                            <span>France</span>
                        </p>
                    </li>
                </ul>
                <p>
                    <span>Numéro de téléphone :</span>
                    <span>06 77 91 72 58</span>
                    <span>(prix d'un appel local)</span>
                </p>
            </div>
        </section>
    );
}
