import Links from "./Links";
import ScrollTopButton from "./ScrollTopButton";
import InfosPratiques from "./InfosPratiques";
import Copyright from "./Copyright";
import Partenaires from "./Partenaires"

export default function FooterElements() {
    return (
            <footer>
                <ScrollTopButton/>
                <InfosPratiques/>
                <Partenaires/>
                <Links/>
                <Copyright/>
            </footer>
    )
}