import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import InstagramCard from './InstagramCards';

import images_insta from '../../images/Insta';
//parametrage pour chaque type d'écran
const responsive = {
  big_desktop: {
    breakpoint: { max: 3000, min: 1600 },
    items: 5,
    slidesToSlide: 5,
    // optional, default to 1.
  },
    desktop: {
        breakpoint: { max: 1600, min: 1024 },
        items: 4,
        slidesToSlide: 4,
       // optional, default to 1.
      },
      laptop: {
        breakpoint: { max: 1024, min: 780 },
        items:3,
        slidesToSlide:3, // optional, default to 1.
       
      },
      tablet: {
        breakpoint: { max: 780, min: 480 },
        items:2,
        slidesToSlide:2, // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 480, min: 0 },
        items:1,
        slidesToSlide:1,
        // optional, default to 1.
      }
  };




export default function SliderInstagram() {
    const plantes = [
        { id: 1, srcImg:images_insta.insta1},
        { id: 2,  srcImg:images_insta.insta2},
        { id: 3, srcImg:images_insta.insta3},
        { id: 4,  srcImg:images_insta.insta4},
        { id: 5,  srcImg:images_insta.insta5},
        { id: 6, srcImg:images_insta.insta6},
        { id: 7,  srcImg:images_insta.insta7},
        { id: 8,  srcImg:images_insta.insta8},
        { id: 9,  srcImg:images_insta.insta9},
    ]

    //objet product qui correspond à la liste html de toutes les cartes instagram grace au .map
    const product = plantes.map((item) => (
        <InstagramCard srcImg={item.srcImg}/>
      ));
      //parametrage des point de visualisations
      const CustomDot = ({ onClick, ...rest }) => {
        const {
          active,
          carouselState: { currentSlide, deviceType }
        } = rest;
        const carouselItems = product;
        // onMove means if dragging or swiping in progress.
        // active is provided by this lib for checking if the item is active or not.
        return (
          <button className=  { active ? "button-slider-active-instagram button-slider-instagram" : "button-slider-inactive-instagram button-slider-instagram"} onClick={() => onClick()}></button>
        );
      };
    return (
        <div className="slider-card-instagram">
          <div className='text-slider-instagram'>
          <h1 className='titre-slider-instagram'><a className='link-titre-slider' target='_blank' rel="noreferrer" href='https://www.instagram.com/fdepalmier/'>@FleurDePalmier</a></h1>
      <h2 className='sous-titre-slider-instagram'>Suivez-nous sur Instagram !</h2>
          </div>
      
      <Carousel autoPlay={true} infinite={true} autoPlaySpeed={5000} transitionDuration={1500} showDots={true} customDot={<CustomDot />} responsive={responsive} removeArrowOnDeviceType={["desktop", "laptop", "big_desktop", "tablet", "mobile"]} containerClass="carousel-container" >
      {product}
      </Carousel>
    </div>
    );
}


