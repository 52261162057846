import React, { useState } from 'react';


export default function PastillePrix() {
  //variable permettant l'affichage ou non du flyer
  const [showPrice, setShowPrice] = useState(false);

  //fonctiononClick
  function handleClick() {
    setShowPrice(!showPrice);
  }
  //display
  return (
    <div className="card-wrapper" onClick={handleClick}>
      {/* section du flyer caché lorsqu'on n'a pas cliqué sur la carte*/}
        {showPrice ? (
          <div className="flyer-background" onClick={handleClick}>
            <img className="flyer" src={'FlyerPastillePrix.jpg'} alt="Flyer" />
          </div>
        ) : null}
        <div className="pastille-prix">
        <h2>Nos Tarifs</h2>
      </div>
    </div>
  );
}
