import React, { useEffect, useState } from 'react';

import SelectionCards from "../Cards/SelectionCards";

import img1 from "../../../images/Abonnement/bouquethiver15e.jpg";
import img2 from "../../../images/Abonnement/bouquethiver25e.jpg";
import img3 from "../../../images/Abonnement/bouquethiver25e2.jpg";
import img4 from "../../../images/Abonnement/bouquethiver30e.jpg";
import img5 from "../../../images/Abonnement/bouquethiver30e2.jpg";
import img7 from "../../../images/Abonnement/bouquethiverseche20e.jpg";
import img8 from "../../../images/Abonnement/bouquethiverseche25e.jpg";
import img9 from "../../../images/Abonnement/minibouquethiver6e.jpg";
import useEmblaCarousel from 'embla-carousel-react'
import { getCompositions } from "../../../lib";
import { isLocalHost } from '../../../config';
export default function CollectionServices() {
  const [isMobile, setIsMobile] = useState(false);
  const [cardData, setCardData] = useState([
    {
      "texte_compositions": "Petit Bouquet (hiver)",
      "prix_compositions": "15€",
      "img_path" : ""
    },
    {
      "texte_compositions": "Bouquet Intermédiaire (hiver)",
      "prix_compositions": "25€",
      "img_path" : ""
    },
    {
      "texte_compositions": "Bouquet Intermédiaire (hiver)",
      "prix_compositions": "25€",
      "img_path" : ""
    },
    {
      "texte_compositions": "Grand Bouquet (hiver)",
      "prix_compositions": "30€",
      "img_path" : ""
    },
    {
      "texte_compositions": "Grand Bouquet (hiver)",
      "prix_compositions": "30€",
      "img_path" : ""
    },
    {
      "texte_compositions": "Bouquet Intermédiaire (hiver)",
      "prix_compositions": "20€",
      "img_path" : ""
    },
    {
      "texte_compositions": "Bouquet Intermédiaire (hiver)",
      "prix_compositions": "25€",
      "img_path" : ""
    },
    {
      "texte_compositions": `Mini Bouquet (hiver)`,
      "prix_compositions": "6€",
      "img_path" : ""
    },
  ]);
  const [initData, setInitData] = useState(true);
  useEffect(() => {
    if(!isLocalHost){
      getCompositions().then(result => {
        setCardData(result.data)
        console.log(result.data); // Access the data property of the result
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    }
  }, [initData]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 781);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [emblaRef] = useEmblaCarousel()

  return (
    <div className="section-card-service">
      <h1 className="titre-collection">Nos créations originales</h1>
      <h2 className="sous-titre-collection">Découvrez notre collection de fleurs, plantes et cadeaux uniques !</h2>
      <h2 className="sous-titre-collection-2">{`Les bouquets étant réalisés uniquement avec des fleurs de saison, en hiver les tiges sont plus courtes. \n Je vous propose donc des compositions sur mousse…. Ou des fleurs séchées issues également du jardin`}</h2>
      {isMobile ? (
        
        <div className="embla" ref={emblaRef}>
      <div className="embla__container">
          <SelectionCards nom={cardData[0]["texte_compositions"]} price={cardData[0]["prix_compositions"]} srcImg={cardData[0]["img_path"]} />
          <SelectionCards nom={cardData[1]["texte_compositions"]} price={cardData[1]["prix_compositions"]} srcImg={cardData[1]["img_path"]} />
          <SelectionCards nom={cardData[2]["texte_compositions"]} price={cardData[2]["prix_compositions"]} srcImg={cardData[2]["img_path"]} />
          <SelectionCards nom={cardData[3]["texte_compositions"]} price={cardData[3]["prix_compositions"]} srcImg={cardData[3]["img_path"]} />
          <SelectionCards nom={cardData[4]["texte_compositions"]} price={cardData[4]["prix_compositions"]} srcImg={cardData[4]["img_path"]} />
          <SelectionCards nom={cardData[5]["texte_compositions"]} price={cardData[5]["prix_compositions"]} srcImg={cardData[5]["img_path"]} />
          <SelectionCards nom={cardData[6]["texte_compositions"]} price={cardData[6]["prix_compositions"]} srcImg={cardData[6]["img_path"]} />
          <SelectionCards nom={cardData[7]["texte_compositions"]} price={cardData[7]["prix_compositions"]} srcImg={cardData[7]["img_path"]}/>
          
          </div>
    </div>
        
      ) : (
        <div className="card-selection-section">
          <SelectionCards nom={cardData[0]["texte_compositions"]} price={cardData[0]["prix_compositions"]} srcImg={cardData[0]["img_path"]} />
          <SelectionCards nom={cardData[1]["texte_compositions"]} price={cardData[1]["prix_compositions"]} srcImg={cardData[1]["img_path"]} />
          <SelectionCards nom={cardData[2]["texte_compositions"]} price={cardData[2]["prix_compositions"]} srcImg={cardData[2]["img_path"]} />
          <SelectionCards nom={cardData[3]["texte_compositions"]} price={cardData[3]["prix_compositions"]} srcImg={cardData[3]["img_path"]} />
          <SelectionCards nom={cardData[4]["texte_compositions"]} price={cardData[4]["prix_compositions"]} srcImg={cardData[4]["img_path"]} />
          <SelectionCards nom={cardData[5]["texte_compositions"]} price={cardData[5]["prix_compositions"]} srcImg={cardData[5]["img_path"]} />
          <SelectionCards nom={cardData[6]["texte_compositions"]} price={cardData[6]["prix_compositions"]} srcImg={cardData[6]["img_path"]} />
          <SelectionCards nom={cardData[7]["texte_compositions"]} price={cardData[7]["prix_compositions"]} srcImg={cardData[7]["img_path"]}/>
          
          </div>
      )}
    </div>
  );
}