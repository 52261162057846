import TextBox from "../Contact/TextBox";
import TextBoxChoices from "../Contact/TextBoxChoices";
import ProductBox from "../ClickCollect/ProductBox.jsx";
import MessageBox from "../Contact/MessageBox";
import '../../css/ContactForm.css';
import React, { useState } from "react";
import {postCommande} from "../../lib"


export default function ClickForm() {
       
    // variables des données
    const [gender, setGender] = useState("M");
    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [message, setMessage] = useState("");
    const [messageAutre, setMessageAutre] = useState("");
    var dt = new Date();
    const year  = dt.getFullYear();
    const month = (dt.getMonth() + 1).toString().padStart(2, "0");
    const day   = (dt.getDate()+2).toString().padStart(2, "0");
    const date = year+"-"+month+"-"+day;
    const [time, setTime] = useState("Matinée 10h-12h");
    const [location, setLocation] = useState("Romagny");
    const [basket, setBasket] = useState([]);
    const [selectedDate, setSelectedDate] = useState(date);
    //variables des conditions
    const [isEmailSent, setIsEmailSent] = useState(true);
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    //set up des box de selection 
    const [object, setObject] = useState("Information Générale");
    const formGender = [{ value: "M", content: "Monsieur" }, { value: "F", content: "Madame" }, { value: "O", content: "Autre" }];
    const formTime = [{ value: "Matinée 10h-12h", content: "Matinée 10h-12h" }, { value: "Après-Midi 16h-18h30", content: "Après-Midi 16h-18h30" }];
    const formLocation = [{ value: "Romagny", content: "Romagny" }, { value: "Saint-Jean du Corail", content: "Saint-Jean du Corail" }, { value: "Saint Hilaire", content: "Saint Hilaire" }];
    const formObject = [{ value: "Information Générale", content: "Information Générale" }, { value: "Anomalie Internet", content: "Anomalie Internet" }, { value: "Reclamation", content: "Réclamation" }, { value: "Autre", content: "Autre" }];
    /*-----------------------------------*/
    //getter callback de toutes les champs de données
    function getNameInput(childData) {
        setName(childData)
    }
    function getGenderInput(childData) {
        setGender(childData)
    }
    function getLastNameInput(childData) {
        setLastName(childData)
    }
    function getEmailInput(childData) {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        setIsValidEmail(emailRegex.test(childData));
        if(isValidEmail){
            setEmail(childData)
        }
        else{setEmail("")}
        
    }
    function getAddressInput(childData) {
        setAddress(childData)
    }
    function getCityInput(childData) {
        setCity(childData)
    }
    function getZipCodeInput(childData) {
        setZipCode(childData)
    }
   
    function getObjectInput(childData) {
        setObject(childData)
    }
    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
      };
    function getTime(childData) {
        setTime(childData)
    }
    function getLocation(childData) {
        setLocation(childData)
    }
    function getBasket(childData) {
        setBasket(childData);
    }
    function getMessageInput(childData) {
        setMessageAutre(childData)
    }
    //fonction de validation des données
    const handleSubmit = (event)=>{
        //on vérifie la conformité du mail pour ne pas envoyer des données avec erreurs
    if(isValidEmail===false){
     setDisplayError(true)
    }
    //on vérifie que les champs obligatoires sont remplis
     else if(email==="" || name==="" || lastName==="" )
     {
        //si vide on ne fait rien 
        //possibilité de mettre un pop up d'alerte simple et n'implqie pas de html
     }
     else{
        //on verouille l'envoie de mail pour éviter les spams
         setIsEmailSent(false)
        //on créer le json à envoyer avec toutes les variables
         const postData = {
             email: email,
             name: name,
             last_name: lastName,
             gender: gender,
             address: address,
             city: city,
             zip_code: zipCode,
             basket: basket, 
             date: date, 
             time: time, 
             messageAutre: messageAutre, 
             location: location
           };
          //on lance la fonction vers le back utilisant axios avec comme argument nos données à envoyer
         postCommande(postData)
         //on réinitialise les champs
             setEmail("")
             setName("")
             setLastName("")
             setGender("")
             setAddress("")
             setCity("")
             setZipCode("")
     }
    }
    // affichage (render)
    return (
        <section className="contact-section">
            <div className="contact-center-element">
                <p className="contact-text-lead">
                    Contactez la Palmeraie par téléphone :
                    <br/>
                    06 77 91 72 58 (prix d'un appel local)
                </p>
                <p className="contact-text-lead-2">Ou envoyez un message :</p>
                <div className="collect-form-background">
                    <div className="contact-content">
                        <form id="form1" className="contact-column">
                            <TextBoxChoices title="Civilité" itemsList={formGender} handleCallBack={getGenderInput} />
                            <TextBox title="Nom *" handleCallBack={getLastNameInput}/>
                            <TextBox title="Prénom *" handleCallBack={getNameInput}/>
                            <TextBox title="Email *" handleCallBack={getEmailInput}/> 
                            {displayError ? isValidEmail ? <p className="valid-email-text">L'adresse e-mail est valide.</p> :<p className="invalid-email-text">L'adresse e-mail n'est pas valide.</p>:<></>}
                            <TextBox title="Adresse" handleCallBack={getAddressInput}/>
                            <TextBox title="Ville" handleCallBack={getCityInput}/>
                            <TextBox title="Code postal" handleCallBack={getZipCodeInput}/>
                            <MessageBox title="Message" handleCallBack={getMessageInput} placeholder="Indiquez ici votre message ainsi que vos choix de produits si nécessaire."/>
                        </form>
                        <form id="form2" className="contact-column">
                            <ProductBox title="Produits" itemsList={formObject} handleCallBack={getObjectInput} setMessageProduct={getBasket}/>
                            <TextBoxChoices title="Lieu" itemsList={formLocation} handleCallBack={getLocation}/>
                            <label className="label-date" for="start">Séléctionnez la date de récupération :
                                <input className="date-selector" type="date" id="start" name="trip-start" onChange={handleDateChange} value={selectedDate} min= {date} max="2050-12-31"></input>
                            </label>
                            <TextBoxChoices title="Partie de la journée" itemsList={formTime} handleCallBack={getTime}/>
                        </form>
                    </div>
                    <div className="contact-button-content-center">
                        <div className="contact-button-content">
                            <span>* Champs obligatoires</span>
                            {isEmailSent ? 
                                <button className="contact-button" onClick={handleSubmit}>
                                    Envoyer
                                </button> 
                                :
                                <button className="contact-button-clicked" >
                                    Envoyé
                                </button> 
                            }   
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
