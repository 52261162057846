import React, { useState } from 'react';


export default function CardAnnuaire({ nom, price, description, srcImg }) {
  //variable permettant l'affichage ou non du flyer
  const [showFlyer, setShowFlyer] = useState(false);
  //
  const [flyerName, setFlyerName] = useState(getPlantNameFromPath(srcImg))
  //permet de créer un str en fonction du nom de la plante dan sla bdd et ainsi retrouver le flyer associé
  function getPlantNameFromPath(path) {
    const parts = path.split('/');
    const plantNameIndex = parts.indexOf('Plantes') + 2;
    if (plantNameIndex < parts.length) {
      return parts[plantNameIndex].slice(0, parts[plantNameIndex].length -4);
    }
    return null;
  }

  //fonctiononClick
  function handleClick() {
    setFlyerName(getPlantNameFromPath(srcImg))
    setShowFlyer(!showFlyer);
  }
  //display
  return (
    <div className="card-wrapper" onClick={handleClick}>
      {/* section du flyer caché lorsqu'on n'a pas cliqué sur la carte*/}
        {showFlyer ? (
          <div className="flyer-background" onClick={handleClick}>
            <img className="flyer" src={'images/Plantes/FLYER/'+ flyerName+'_Flyer.png'} alt="Flyer" />
          </div>
        ) : null}
        <div className="card-annuaire">
        <img className="img-card-annuaire" src={srcImg} alt="plante" loading="lazy" />
        <p className="nom-card-annuaire">{nom}</p>
        <p className="description-card-annuaire">{description}</p>
      </div>
    </div>
  );
}
