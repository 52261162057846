export const location = [{
    id: 1,
    content: "Tout",
    text: "Lieu de vie",
  },
  {
    id: 2,
    content: "intérieur",
    text: "Intérieur",
  },
  {
    id: 3,
    content: "extérieur",
    text: "Extérieur",
  },
  
];

export const vegetalFamily = [{
    id: 1,
    content: "Tout",
    text: "Catégorie",
  },
  {
    id: 2,
    content: "Palmiers",
    text: "Palmiers",
  },
  {
    id: 3,
    content: "Cactus",
    text: "Cactus",
},
{
    id: 4,
    content: "Yuccas",
    text: "Yuccas",
  },
  {
    id: 5,
    content: "Agaves",
    text: "Agaves",
  },
  {
    id: 6,
    content: "Comestibles",
    text: "Comestibles",
  },
  {
    id: 7,
    content: "Autres",
    text: "Autres",
  },
  
  
]