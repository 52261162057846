import {useState} from "react";

export default function ButtonFilter(props) {
    
    const [currentValue, setCurrentValue] = useState();
    const filters = props.content

    props.handleCallBack(currentValue);
    

    function handleChange(event) {
        setCurrentValue(event.target.value);
      };
    return (
        <div className="button-filter-div">
            <img className="logo-filter-bar" src={props.logo} alt="logo"/>
            <select className="select-button-filter" value={currentValue} onChange={handleChange}>
                {filters.map((item) =>
                  <option className="option-button-filter" key={item.id} value={item.content}>{item.text}</option>
                )}
            </select>
        </div>
    );
}